import { Component, ErrorInfo, ReactNode } from "react";
import DeviceHelper, { Device } from "../../helpers/DeviceHelper";

interface ErrorProps {
  children: ReactNode;
  fallbackComponent: JSX.Element;
}

interface ErrorState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  public state: ErrorState = {
    hasError: false,
  };

  constructor(props: ErrorProps) {
    super(props);
  }

  public static getDerivedStateFromError(_: Error): ErrorState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallbackComponent;
    }

    return this.props.children;
  }
}

export interface ABTestSwitcherProps {
  components: { [key: string]: JSX.Element }; // keyはboronのAbTestCodeモデルのcodeに対応します。形式に決まりがあるので確認してください。
  codes: string[] | undefined;
  defaultComponent: JSX.Element;
  devices?: Device[];
}

/**
 * ABテスト用にコンポーネントをスイッチするコンポーネントです。
 * codesのうち最初にマッチしたコンポーネントを呼び出すか、該当コンポーネントがない場合やエラーが起きた場合にdefaultComponentを呼び出します。
 *
 * サンプルコード
 * ```ts
 * const Foo = (props: { foo: string }) => { return (<div>Foo {props.foo}</div>); };
 * const Bar = () => { return (<div>Bar</div>); };
 * const Hoge = () => { return (<div>Hoge</div>); };
 *
 * <ABTestSwitcher
 *  components={{
 *    foo: <Foo foo="ふー"/>,
 *    bar: <Bar />,
 *  }}
 *  codes={["foo", "bar", "baz"]}
 *  devices={["mobile", "pc"]}
 *  defaultComponent={<Hoge />}
 * />
 * ```
 *
 * このように書くとFooコンポーネントが呼び出されます。
 *
 * devicesは以下の中から値を選定してください。
 * `type Device = "mobile" | "iosMobile" | "androidMobile" | "tablet" | "pc";`
 *
 * devicesを指定しない場合は、全てのデバイスで表示されます。
 *
 */
export const ABTestSwitcher = ({
  components,
  codes,
  defaultComponent,
  devices,
}: ABTestSwitcherProps) => {
  if (!DeviceHelper.isTargetDevice(devices) || codes === undefined) {
    return defaultComponent;
  }

  const matchedCode = codes.find((code) =>
    Object.hasOwnProperty.call(components, code),
  );
  if (!matchedCode) {
    return defaultComponent;
  }

  const comp = components[matchedCode];
  if (comp) {
    return (
      <ErrorBoundary fallbackComponent={defaultComponent}>{comp}</ErrorBoundary>
    );
  } else {
    return defaultComponent;
  }
};
