import * as React from "react";
import Select from "react-select";
import styleVars from "../../../styles/variables.scss";

type Option = { label: string | number; value: any };

/**
 * Formikと一緒にreact-selectを利用するためのラッパーコンポーネント
 * -> 一旦汎用的に使えるように修正。KAIZENでFormik依存のところは剥がして別のラッパーコンポーネントに分けたい。
 */
// TODO: propsがanyになってるのを後でなんとかする
const SelectField: React.FC<any> = (props) => {
  const {
    options,
    field,
    form,
    className,
    noOptionsMessage,
    hasError,
    onChange,
    onBlur,
    name,
    value,
    defaultValue,
    disabled,
    inputId,
  } = props;

  const isError = (): boolean => {
    return form
      ? (form.touched as any)[field.name] && (form.errors as any)[field.name]
      : hasError;
  };

  const filterOption = (option: Option, rawInput: string): boolean => {
    // NOTE:
    // 本来はOptionの型の通りにしたいが、
    // FormikのFieldの型定義としてそこまで型を絞ることが難しいため、
    // labelにnumberを許容している。そのため、numberのときはstringへの変換が必要になっている。
    // FieldSelectFieldの型指定が
    // ref: https://github.com/jaredpalmer/formik/blob/a2b66c52dcbe5998880b9a79eb97c613a7a2f62e/src/Field.tsx#L23-L26
    const label =
      typeof option.label === "number" ? option.label.toString() : option.label;
    return -1 !== label.indexOf(rawInput);
  };

  return (
    <Select
      defaultValue={defaultValue}
      options={options}
      inputId={inputId}
      name={field?.name ?? name ?? ""}
      value={
        options
          ? options.find((option: Option) => {
              if (value) {
                return option.value === value;
              } else {
                return field && option.value === field?.value;
              }
            })
          : ""
      }
      onChange={(option: any) => {
        if (form) {
          form.setFieldValue(field.name, option ? option.value : null);
        }
        onChange?.(option);
      }}
      onBlur={() => {
        if (form) {
          form.setFieldTouched(field.name);
        }
        onBlur?.();
      }}
      isDisabled={disabled}
      className={className}
      placeholder={props.placeholder}
      noOptionsMessage={() => noOptionsMessage || "見つかりませんでした"}
      isSearchable={props.isSearchable}
      isClearable={props.isClearable}
      filterOption={filterOption}
      styles={{
        control: (styles: any, state: any) => {
          const baseStyles = {
            ...styles,
            height: "4.6rem",
            borderWidth: "1px",
            borderColor: state.isFocused ? styleVars.colorPrimary : "#dde0e8",
            boxShadow: "none",
            color: styleVars.colorBlack,
            borderRadius: "5px",
            "&:hover": {
              borderColor: state.isFocused ? styleVars.colorPrimary : "#dde0e8",
              boxShadow: "none",
            },
          };

          if (isError()) {
            return {
              ...baseStyles,
              backgroundColor: "#fbf0f0",
              borderColor: "#f03d3d",
            };
          }
          return baseStyles;
        },
        placeholder: (styles: any) => ({
          ...styles,
          color: styleVars.colorBlack,
          opacity: 0.5,
        }),
        singleValue: (styles: any) => ({
          ...styles,
          color: styleVars.colorBlack,
          maxWidth: "calc(94% - 8px)",
        }),
        option: (styles: any, state: any) => ({
          ...styles,
          color: state.isSelected ? "#fff" : styleVars.colorBlack,
          background: state.isSelected
            ? styleVars.colorPrimary
            : styles.background,
          "&:hover": {
            background: state.isSelected
              ? styleVars.colorPrimary
              : styleVars.colorGrayLighten2,
          },
        }),
        indicatorSeparator: (styles: any) => ({
          ...styles,
          display: props.hideIndicator ? "none" : "block",
        }),
      }}
    />
  );
};

export default SelectField;
