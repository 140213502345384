import { useBoronMutation } from "../../../../hooks/http/useBoronMutation.ts";
import { boronClient } from "../../../../api.ts";
import { UnprocessableEntityError } from "../../../../errors.ts";
import { useFlashMessage } from "../../../../hooks/useFlashMessage.ts";
import { ZoomMeetingSettingInterface } from "../../../../interfaces/OperatorProfileInterface.ts";
import { useUpdateProfileCache } from "../../../../hooks/http/useFetchProfile.ts";

type Params = ZoomMeetingSettingInterface;
export const useUpdateZoomMeetingSetting = () => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const mutation = useBoronMutation(
    async (params: Params) =>
      await boronClient.PATCH(
        "/api/v1/zoom/meeting_settings/{meeting_setting_hashid}",
        {
          params: {
            path: {
              meeting_setting_hashid: params.id,
            },
          },
          body: paramsToRequestBody(params),
        },
      ),
  );

  const { updateProfileCache } = useUpdateProfileCache();

  const updateZoomMeetingSetting = (params: Params) => {
    mutation.mutate(params, {
      onSuccess: async (data) => {
        updateProfileCache({
          zoomMeetingSetting: data.zoomMeetingSetting,
        });
        showSuccessMessage("ミーティング設定を更新しました");
      },
      onError: () => {
        showErrorMessage("ミーティング設定を更新できませんでした");
      },
    });
  };

  const apiErrors =
    mutation.error instanceof UnprocessableEntityError
      ? mutation.error.originalErrors
      : [];
  return { ...mutation, updateZoomMeetingSetting, apiErrors };
};

const paramsToRequestBody = (params: Params) => ({
  waiting_room: params.waitingRoom,
  enabled_password: params.enabledPassword,
  join_before_host: params.joinBeforeHost,
  mute_upon_entry: params.muteUponEntry,
  auto_recording: params.autoRecording,
});
