import * as React from "react";
import StaffBoardPostItem from "../../../../components/molecules/StaffBoardPostItem";
import OperatorInterface from "../../../../interfaces/OperatorInterface";
import BackLink from "../../../../components/atoms/BackLink";
import SectionInterface from "../../../../interfaces/SectionInterface";
import Loader from "../../../../components/atoms/Loader";
import { StaffBoardPostCommentFormInterface } from "../../../../interfaces/StaffBoardPostInterface";
import {
  useFetchStaffBoardPost,
  useAddStaffBoardPostCommentCache,
  useDropStaffBoardPostCommentCache,
} from "./useFetchStaffBoardPost";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import { useNavigate } from "react-router-dom";
import { FSHTTPError } from "../../../../errors";
import { HTTP_ERROR_MESSAGE } from "../../../../reducers";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { useDeleteStaffBoardPost } from "./useDeleteStaffBoardPost";
import { usePostStaffBoardPostComment } from "./usePostStaffBoardPostComment";
import { useDeleteStaffBoardPostComment } from "./useDeleteStaffBoardPostComment";

type Props = {
  section: SectionInterface;
  currentOperator: OperatorInterface;
  staffBoardPostId: string;
};

const SectionStaffBoardPost = (props: Props) => {
  const { section, currentOperator, staffBoardPostId } = props;

  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const navigate = useNavigate();

  const { isPending, data, error } = useFetchStaffBoardPost({
    sectionId: section.id,
    staffBoardPostId,
  });
  useQueryError(error);

  const { mutate: deletePost } = useDeleteStaffBoardPost({
    sectionId: section.id,
    staffBoardPostId,
  });

  const { mutate: postComment, isPending: isSubmittingComment } =
    usePostStaffBoardPostComment({
      sectionId: section.id,
      staffBoardPostId,
    });
  const { addStaffBoardPostCommentCache } = useAddStaffBoardPostCommentCache({
    sectionId: section.id,
    staffBoardPostId,
  });

  const { mutate: deleteComment, isPending: isDeletingComment } =
    useDeleteStaffBoardPostComment({
      sectionId: section.id,
      staffBoardPostId,
    });
  const { dropStaffBoardPostCommentCache } = useDropStaffBoardPostCommentCache({
    sectionId: section.id,
    staffBoardPostId,
  });

  const handleDeletePost = () => {
    deletePost(undefined, {
      onSuccess: () => {
        showSuccessMessage("投稿を削除しました");
        navigate(`/sections/${section.id}/staff_board_posts`);
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage("投稿を削除できませんでした");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    });
  };

  const handlePostComment = (values: StaffBoardPostCommentFormInterface) => {
    postComment(values, {
      onSuccess: (data) => {
        showSuccessMessage("コメントを投稿しました");
        addStaffBoardPostCommentCache(data.staffBoardPostComment);
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage("コメントを投稿できませんでした");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    });
  };

  const handleDeleteComment = (commentId: string) => {
    deleteComment(
      { commentId },
      {
        onSuccess: () => {
          showSuccessMessage("コメントを削除しました");
          dropStaffBoardPostCommentCache(commentId);
        },
        onError: (error) => {
          if (error instanceof FSHTTPError) {
            showErrorMessage("コメントを削除できませんでした");
          } else {
            showErrorMessage(HTTP_ERROR_MESSAGE);
          }
        },
      },
    );
  };

  return (
    <React.Fragment>
      <BackLink>戻る</BackLink>
      {isPending || !data ? (
        <Loader loading />
      ) : (
        <StaffBoardPostItem
          section={section}
          currentOperator={currentOperator}
          staffBoardPost={data}
          submitting={isSubmittingComment || isDeletingComment}
          postComment={handlePostComment}
          deleteComment={handleDeleteComment}
          deletePost={handleDeletePost}
        />
      )}
    </React.Fragment>
  );
};

export default SectionStaffBoardPost;
