import SectionInterface from "../../../interfaces/SectionInterface";
import enhanceSectionsPage from "../../../hocs/enhanceSectionsPage";
import SectionStaffBoardPostEdit from "./SectionStaffBoardPostEdit";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import { useEffect } from "react";
import Loader from "../../../components/atoms/Loader";
import { useParams } from "react-router";

interface Props extends OutletContextProps {
  currentOperator: OperatorInterface | undefined;
  section: SectionInterface;
}

const SectionStaffBoardPostPage = (props: Props) => {
  const { setActiveMenu, section, currentOperator } = props;

  useEffect(() => {
    setActiveMenu("dashboard");
  }, []);

  const params = useParams<{ staffBoardPostId: string }>();

  if (!params.staffBoardPostId || !currentOperator) {
    return <Loader loading />;
  }

  return (
    <SectionStaffBoardPostEdit
      section={section}
      currentOperator={currentOperator}
      staffBoardPostId={params.staffBoardPostId}
    />
  );
};

const pageInfo = {
  title: "スタッフボード",
};

export default enhanceSectionsPage(SectionStaffBoardPostPage, pageInfo);
