import * as React from "react";
import {
  Chart,
  ChartData,
  ChartOptions,
  Color,
  Plugin,
  registerables,
  TooltipItem,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styleVars from "../../../styles/variables.scss";
import { useEffectAvoidFirst } from "../../../hooks/useEffectAvoidFirst";

// Main hooks

type UseAnalyticsChartProps = {
  dataSets: ChartData<"pie">["datasets"] | null;
  chartOptions?: ChartOptions<"pie">;
};
const useAnalyticsPieChart = ({
  dataSets,
  chartOptions,
}: UseAnalyticsChartProps) => {
  const chartRef = React.useRef<Chart<"pie">>();
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const mediaQueryListRef = React.useRef<MediaQueryList>();
  React.useLayoutEffect(() => {
    const ctx = canvasRef.current?.getContext("2d");
    Chart.register(...registerables);

    if (ctx && dataSets && chartOptions) {
      chartRef.current = new Chart(ctx, {
        plugins: [ChartDataLabels as Plugin<"pie">],
        type: "pie",
        options: chartOptions,
        data: {
          datasets: dataSets,
        },
      });
    }
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [canvasRef.current]);

  useEffectAvoidFirst(() => {
    if (chartRef.current && dataSets) {
      chartRef.current.data = { ...chartRef.current.data, datasets: dataSets };
      chartRef.current.update();
    }
  }, [chartRef.current, dataSets]);

  const handleBeforePrint = (e: MediaQueryListEvent) => {
    if (!chartRef.current) return;
    if (e.matches) {
      // NOTE: 印刷時はグラフサイズをレスポンシブにしないことで表示崩れを防ぐ
      chartRef.current.options.responsive = false;
      chartRef.current.update();
    } else {
      chartRef.current.options.responsive = true;
      chartRef.current.update();
    }
  };

  React.useEffect(() => {
    if (!mediaQueryListRef.current) {
      mediaQueryListRef.current = window.matchMedia("print");
      mediaQueryListRef.current.addEventListener("change", handleBeforePrint);
    }
    return () => {
      mediaQueryListRef.current?.removeEventListener(
        "change",
        handleBeforePrint,
      );
    };
  }, []);

  return { canvasRef };
};

export type PieChartProps = {
  backgroundColor: Color[];
  data: number[];
  // ツールチップ表示用にフォーマットされた文字列の配列
  tooltipItemLabels: string[];
};

export type NullablePieChartPropsFields = {
  [P in keyof PieChartProps]: PieChartProps[P] | null;
};

type Props = NullablePieChartPropsFields & {
  tooltipTitle: string;
};

const AnalyticsPieChart_ = ({
  data,
  backgroundColor,
  tooltipItemLabels,
  tooltipTitle,
}: Props) => {
  const { canvasRef } = useAnalyticsPieChart({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          color: "#4f4e5d",
          font: {
            size: 14,
          },
          formatter: (value) => {
            if (value === 0) {
              return "";
            }
            return `${value}%`;
          },
          anchor: "center",
          align: "center",
          offset: 20,
        },
        tooltip: {
          padding: {
            top: 10,
            bottom: 10,
            left: 16,
            right: 16,
          },
          backgroundColor: styleVars.colorBlackLighten1,
          cornerRadius: 4,
          caretSize: 10,
          callbacks: {
            label: (tooltipItem: TooltipItem<"pie">) => {
              const itemLabel = tooltipItemLabels
                ? tooltipItemLabels[tooltipItem.dataIndex]
                : [];
              return `${itemLabel}`;
            },
            title() {
              return tooltipTitle;
            },
          },
        },
      },
    },
    dataSets:
      data && backgroundColor
        ? [{ data, backgroundColor, borderWidth: 1 }]
        : null,
  });
  return <canvas ref={canvasRef} />;
};

export const AnalyticsPieChart = React.memo(
  AnalyticsPieChart_,
  (prev, next) => {
    // tooltipItemLabelにはパーセンテージと教材タグのタイトルが含まれるので、それらが同じなら全く同じデータが選択されていると言える
    return (
      JSON.stringify(prev.tooltipItemLabels) ===
      JSON.stringify(next.tooltipItemLabels)
    );
  },
);
