import enhanceHomePage from "../../../hocs/enhanceHomePage.tsx";
import OperatorInterface from "../../../interfaces/OperatorInterface.ts";
import SettingsProfileEdit from "./SettingsProfileEdit";

interface Props {
  currentOperator: OperatorInterface;
}

const Index = (props: Props) => {
  return <SettingsProfileEdit operator={props.currentOperator} />;
};

const pageInfo = {
  title: "アカウント設定",
};

export default enhanceHomePage(Index, pageInfo);
