import { boronClient } from "../../../../api.ts";
import { ActionTypes as UserInfoActionTypes } from "../../../../actions/authorization/userInfo.ts";
import { useDispatch } from "react-redux";

export const useFetchAndUpdateUserInfo = () => {
  const dispatch = useDispatch();

  const fetchAndUpdateUserInfo = async () => {
    const { data, response } = await boronClient.GET("/api/v1/me");
    if (response.ok && data) {
      // reduxのアカウント情報を更新
      dispatch({
        type: UserInfoActionTypes.GET_USER_INFO_SUCCESS,
        payload: data,
      });
    }
  };

  return { fetchAndUpdateUserInfo };
};
