import * as React from "react";
import styles from "./styles.scss";
import OperatorInterface from "../../../../../interfaces/OperatorInterface.ts";
import { FormikProps, FormikBag, withFormik, Form, Field } from "formik";
import Input from "../../../../../components/atoms/Input";
import Label from "../../../../../components/atoms/Label";
import { Modal } from "../../../../../components/atoms/Modal";
import Button from "../../../../../components/atoms/Button";
import { ApiErrorInterface } from "../../../../../interfaces/ApiErrorResponseInterface.ts";
import validationSchema from "./validationSchema.ts";

interface ComponentProps {
  operator: OperatorInterface;
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
  updateName: (firstName: string, lastName: string) => void;
}
interface Values {
  firstName: string;
  lastName: string;
}

interface State {
  isOpen: boolean;
}

type Props = ComponentProps & FormikProps<Values>;

class OperatorProfileNameForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  componentDidUpdate(previousProps: Props) {
    if (!this.equalOperatorNames(previousProps.operator, this.props.operator)) {
      // The operator's name is supposed to be updated through API
      this.setState({ isOpen: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.root}>
          <Label isMute={true}>アカウント名</Label>
          <div className={styles.display}>
            {this.props.operator.fullName}
            <button className={styles.btn} onClick={this.handleOpenModal}>
              変更
            </button>
          </div>
        </div>
        {this.renderModal()}
      </React.Fragment>
    );
  }

  renderModal() {
    return (
      <Modal isOpen={this.state.isOpen} onRequestClose={this.handleCloseModal}>
        <Modal.Header onClose={this.handleCloseModal}>
          <h2>アカウント名変更</h2>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {this.renderApiErrors()}
            <div className={styles.inline}>
              {this.renderLastNameForm()}
              {this.renderFirstNameForm()}
            </div>
            <div className={styles.btnGroup}>
              <Button type="submit" disabled={!this.isSubmittable()}>
                更新
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  renderLastNameForm() {
    return (
      <div className={styles.formGroup}>
        <div className={styles.inline}>
          <Label isMute={true} htmlFor="operator-last_name">
            姓
          </Label>
          {this.renderErrors("lastName")}
        </div>
        <Field
          name="lastName"
          id="operator-last_name"
          hasError={false}
          component={Input}
        />
      </div>
    );
  }

  renderFirstNameForm() {
    return (
      <div className={styles.formGroup}>
        <div className={styles.inline}>
          <Label isMute={true} htmlFor="operator-first_name">
            名
          </Label>
          {this.renderErrors("firstName")}
        </div>
        <Field
          name="firstName"
          id="operator-first_name"
          hasError={false}
          component={Input}
        />
      </div>
    );
  }

  renderErrors(name: string) {
    if (this.shouldRenderError(name)) {
      return (
        <p className={styles.input__error}>
          {(this.props.errors as any)[name]}
        </p>
      );
    }
  }

  renderApiErrors() {
    if (this.props.apiErrors.length > 0) {
      return this.props.apiErrors.map((err: ApiErrorInterface, idx: number) => {
        return (
          <p className={styles.input__error} key={`${name}-error-${idx}`}>
            {err.message}
          </p>
        );
      });
    }
  }

  private equalOperatorNames(
    operatorA: OperatorInterface,
    operatorB: OperatorInterface,
  ) {
    return (
      operatorA.firstName === operatorB.firstName &&
      operatorA.lastName === operatorB.lastName
    );
  }

  private shouldRenderError = (name: string): boolean => {
    const errors = (this.props.errors as any)[name];
    const touched = (this.props.touched as any)[name];
    return errors && touched;
  };

  private handleCloseModal = () => {
    this.setState({ isOpen: false });
  };

  private handleOpenModal = () => {
    this.setState({ isOpen: true });
  };

  private isSubmittable(): boolean {
    return !this.props.submitting && this.props.isValid;
  }
}

const mapPropsToValues = (props: ComponentProps): Values => ({
  firstName: props.operator.firstName,
  lastName: props.operator.lastName,
});

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
): void => {
  if (formikBag.props.submitting) {
    return;
  }
  formikBag.props.updateName(values.firstName, values.lastName);
};
export default withFormik({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
  isInitialValid: true,
})(OperatorProfileNameForm);
