import styles from "./styles.scss";
import Input from "../../../../components/atoms/Input";
import { Button } from "@studyplus/boron-ui";
import Label from "../../../../components/atoms/Label";
import ErrorText from "../../../../components/atoms/ErrorText";
import {
  useClassroomForm,
  Props as UseClassroomFormProps,
} from "./useClassRoomForm";
import { BlockRow } from "../../../../components/atoms/BlockRow";
import { Flex } from "../../../../components/atoms/Flex";
import { InlineBlock } from "../../../../components/atoms/InlineBlock";
import { Text } from "../../../../components/general/Text/Text";
import { HelpLink } from "../../../../components/atoms/HelpLink/index";
import { ExternalLink } from "../../../../components/atoms/ExternalLink/index";
import { DescriptionText } from "../../../../components/atoms/DescriptionText";
import {
  OptionType,
  SelectWrapper,
} from "../../../../components/general/SelectWrapper";
import Icon from "../../../../components/atoms/Icon";
import { OnChangeValue } from "react-select";

const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/5630467";

export const ClassroomForm = (props: UseClassroomFormProps) => {
  const {
    formProps,
    nameProps,
    nameErrorProps,
    nameErrorText,
    latitudeAndLongitudeProps,
    latitudeAndLongitudeErrorProps,
    latitudeAndLongitudeErrorText,
    correctionRangeProps,
    correctionRangeErrorProps,
    correctionRangeErrorText,
    buttonProps,
    buttonLabel,
  } = useClassroomForm(props);
  return (
    <BlockRow marginTop="2.4rem">
      <form {...formProps}>
        <div>
          <Label htmlFor="name">
            <div className={styles.classroomNameLabel}>教室名</div>
          </Label>
          <Input id="name" {...nameProps} placeholder="名称を入力" />
          <ErrorText {...nameErrorProps}>{nameErrorText}</ErrorText>
        </div>
        <Flex alignItems="center" marginTop="2rem">
          <div className={styles.locationHeader}>
            <Text className={styles.text}>位置情報判定の追加</Text>
          </div>
          <div className={styles.description}>
            <DescriptionText>
              予定の出席確認の位置情報を取得する場合に、教室の位置情報を元に判定が行われます。
            </DescriptionText>
          </div>
        </Flex>
        <div className={styles.locationInputContainer}>
          <BlockRow>
            <Flex alignItems="center" marginTop="0rem">
              <Text size="md">
                「地図を見る」で場所を検索して「緯度・経度」の数値をコピーできます。
              </Text>
              <HelpLink to={HELP_URL}>
                <InlineBlock marginLeft="0.4rem">
                  <Text color="primary" className={styles.text}>
                    取得方法はこちら
                  </Text>
                </InlineBlock>
              </HelpLink>
            </Flex>
            <Flex alignItems="center" marginTop="1.6rem">
              <Label htmlFor="latitudeAndLongitude">
                <Text className={styles.locationLabel}>緯度・経度</Text>
              </Label>
              <div className={styles.inputContainer}>
                <Input
                  id="latitudeAndLongitude"
                  name="latitudeAndLongitude"
                  placeholder="例）35.70076688351358, 139.7604972117228"
                  onChange={latitudeAndLongitudeProps.onChange}
                  onBlur={latitudeAndLongitudeProps.onBlur}
                  hasError={latitudeAndLongitudeProps.hasError}
                  value={latitudeAndLongitudeProps.value}
                />
              </div>
              <div className={styles.googlemapButtonContainer}>
                <ExternalLink to="https://google.com/maps">
                  <Button
                    variant="outline"
                    isRound
                    size="sm"
                    type="button"
                    className={styles.googlemapButton}
                  >
                    地図を見る
                    <InlineBlock marginLeft="0.4rem">
                      <Icon name="icon-external-link" />
                    </InlineBlock>
                  </Button>
                </ExternalLink>
              </div>
            </Flex>
            <div className={styles.errorText}>
              <ErrorText {...latitudeAndLongitudeErrorProps}>
                {latitudeAndLongitudeErrorText}
              </ErrorText>
            </div>
          </BlockRow>
          <Flex alignItems="center" marginTop="2rem">
            <Label htmlFor="correctionRange">
              <Text className={styles.locationLabel}>有効範囲</Text>
            </Label>
            <div className={styles.selectContainer}>
              <SelectWrapper
                size="md"
                fontSize="md"
                inputId="correctionRange"
                menuPortalTarget={document.body}
                menuPlacement="bottom"
                menuPosition="absolute"
                menuShouldScrollIntoView={false}
                styles={{
                  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                }}
                isSearchable={false}
                {...correctionRangeProps}
                onChange={(option: OnChangeValue<OptionType, false>) => {
                  correctionRangeProps.onChange(option?.value);
                }}
              />
            </div>
          </Flex>
          <div className={styles.errorText}>
            <ErrorText {...correctionRangeErrorProps}>
              {correctionRangeErrorText}
            </ErrorText>
          </div>
        </div>
        <BlockRow marginTop="2rem">
          <div className={styles.submitContainer}>
            <Button {...buttonProps} type="submit" className={styles.submit}>
              {buttonLabel}
            </Button>
          </div>
        </BlockRow>
      </form>
    </BlockRow>
  );
};
