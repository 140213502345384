import { useBoronMutation } from "../../../../hooks/http/useBoronMutation.ts";
import { boronClient } from "../../../../api.ts";
import { UnprocessableEntityError } from "../../../../errors.ts";
import { useFlashMessage } from "../../../../hooks/useFlashMessage.ts";
import { useFetchAndUpdateUserInfo } from "./useFetchAndUpdateUserInfo.tsx";

export const useUpdateOperatorName = () => {
  const { fetchAndUpdateUserInfo } = useFetchAndUpdateUserInfo();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const mutation = useBoronMutation(
    async ({ lastName, firstName }: { lastName: string; firstName: string }) =>
      await boronClient.PATCH("/api/v1/settings/profile", {
        body: {
          operator: {
            last_name: lastName,
            first_name: firstName,
          },
        },
      }),
  );

  const updateOperatorName = (firstName: string, lastName: string) => {
    mutation.mutate(
      { lastName, firstName },
      {
        onSuccess: async () => {
          // reduxのアカウント情報を更新
          await fetchAndUpdateUserInfo();
          showSuccessMessage("アカウント名を更新しました");
        },
        onError: () => {
          showErrorMessage("アカウント名を更新できませんでした");
        },
      },
    );
  };

  const apiErrors =
    mutation.error instanceof UnprocessableEntityError
      ? mutation.error.originalErrors
      : [];
  return { ...mutation, updateOperatorName, apiErrors };
};
