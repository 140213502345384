import Settings from "../../../../helpers/Settings.ts";
import { useFlashMessage } from "../../../../hooks/useFlashMessage.ts";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { boronClient } from "../../../../api.ts";
import { useBoronMutation } from "../../../../hooks/http/useBoronMutation.ts";
import { useUpdateProfileCache } from "../../../../hooks/http/useFetchProfile.ts";

export const useZoomConnection = ({
  operatorId,
  isPending,
  setIsPendingExternalConnection,
}: {
  operatorId: string;
  isPending: boolean;
  setIsPendingExternalConnection: (isLoading: boolean) => void;
}) => {
  const { showSuccessMessage, showErrorMessage, hideFlashMessage } =
    useFlashMessage();
  const [searchParams] = useSearchParams();

  const connectZoom = () => {
    if (isPending) return;

    setIsPendingExternalConnection(true);
    hideFlashMessage();
    window.location.href = `${Settings.BORON_URL}/zoom/oauth?operator_id=${operatorId}`;
  };

  const connectZoomError = () => {
    setIsPendingExternalConnection(false);
    showErrorMessage("Zoom連携に失敗しました");
  };

  const { mutateAsync } = useBoronMutation(
    async () => await boronClient.DELETE("/api/v1/zoom/deauthorize"),
  );

  const { updateProfileCache } = useUpdateProfileCache();

  const disconnectZoom = async () => {
    if (confirm("Zoomとの連携を本当に解除してよろしいですか？")) {
      await mutateAsync(undefined, {
        onSuccess: () => {
          updateProfileCache({
            zoomConnected: false,
            zoomProfile: null,
          });
          showSuccessMessage("Zoom連携を解除しました");
        },
        onError: () => {
          showErrorMessage("Zoom連携の解除に失敗しました");
        },
      });
    }
  };

  const switchZoomConnection = async (value: boolean): Promise<boolean> => {
    if (value) {
      connectZoom();
    } else {
      await disconnectZoom();
    }

    return true;
  };

  useEffect(() => {
    searchParams.get("auth_zoom") == "failed" && connectZoomError();
  }, [searchParams]);

  return {
    switchZoomConnection,
  };
};
