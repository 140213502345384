import * as React from "react";
import styles from "./RegistrationModal.scss";
import { BlockRow } from "../../../../components/atoms/BlockRow/index";
import { Button } from "@studyplus/boron-ui";
import { Flex } from "../../../../components/atoms/Flex";
import { Text } from "../../../../components/general/Text/Text";
import { InlineBlock } from "../../../../components/atoms/InlineBlock";
import { Modal } from "../../../../components/atoms/Modal";
import Label from "../../../../components/atoms/Label";
import Input from "../../../../components/atoms/Input";
import { HelpLink } from "../../../../components/atoms/HelpLink/index";
import { ExternalLink } from "../../../../components/atoms/ExternalLink/index";
import { FormikErrors, useFormik, FormikHelpers } from "formik";
import * as yup from "yup";
import { useMutateBuildingApi } from "./useMutateBuildingApi";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import ErrorText from "../../../../components/atoms/ErrorText";
import { UnprocessableEntityError } from "../../../../errors";
import { Building } from "../../../../domains/Building";
import {
  SelectWrapper,
  OptionType,
} from "../../../../components/general/SelectWrapper";
import Icon from "../../../../components/atoms/Icon";
import { OnChangeValue } from "react-select";

const options = [
  { label: "半径100メートル以内", value: 100 },
  { label: "半径200メートル以内", value: 200 },
  { label: "半径300メートル以内", value: 300 }, // default
  { label: "半径400メートル以内", value: 400 },
  { label: "半径500メートル以内", value: 500 },
  { label: "半径800メートル以内", value: 800 },
  { label: "半径1000メートル以内", value: 1000 },
];
const defaultOption = options[2];
const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/5638365";

type Props = {
  building: Building | null;
  sectionId: string;
  isOpen: boolean;
  onClose: () => void;
};

type BuildingFormLabelProps = {
  children: React.ReactNode;
  htmlFor?: React.LabelHTMLAttributes<HTMLLabelElement>["htmlFor"];
};
const BuildingFormLabel = ({ children, htmlFor }: BuildingFormLabelProps) => (
  <Label htmlFor={htmlFor} noMargin>
    <Text color="gray-darken-2" bold size="xs" as="span">
      {children}
    </Text>
  </Label>
);
export const RegistrationModal = ({
  isOpen,
  onClose,
  sectionId,
  building,
}: Props) => {
  const form = useForm({ sectionId, onClose, building });

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header onClose={onClose}>位置情報の追加</Modal.Header>
      <Modal.Body>
        <form onSubmit={form.handleSubmit}>
          <div className={styles.modalBodyContainer}>
            <BlockRow>
              <BuildingFormLabel htmlFor="name">場所名</BuildingFormLabel>
              <BlockRow marginTop="0.8rem">
                <Input
                  id="name"
                  name="name"
                  placeholder="場所名を入力"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  hasError={Boolean(form.touched.name && form.errors.name)}
                  value={form.values.name}
                />
                <ErrorText>{form.touched.name && form.errors.name}</ErrorText>
              </BlockRow>
            </BlockRow>

            <BlockRow marginTop="1.6rem">
              <Flex justifyContent="flex-start" alignItems="center" gap="1.6">
                <BuildingFormLabel htmlFor="latitudeAndLongitude">
                  緯度・経度
                </BuildingFormLabel>
                <HelpLink to={HELP_URL}>
                  <InlineBlock marginLeft="0.4rem">
                    <Text color="primary" bold size="xs">
                      経緯度座標の設定方法
                    </Text>
                  </InlineBlock>
                </HelpLink>
              </Flex>
              <Flex alignItems="center" marginTop="0.8rem" gap="1.6">
                <Input
                  id="latitudeAndLongitude"
                  name="latitudeAndLongitude"
                  placeholder="35.70068846983529, 139.76057231330154"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  hasError={Boolean(
                    form.touched.latitudeAndLongitude &&
                      form.errors.latitudeAndLongitude,
                  )}
                  value={form.values.latitudeAndLongitude}
                />
                <ExternalLink to="https://google.com/maps">
                  <Button variant="outline" isRound size="sm" type="button">
                    地図を見る
                    <InlineBlock marginLeft="0.4rem">
                      <Icon name="icon-external-link" />
                    </InlineBlock>
                  </Button>
                </ExternalLink>
              </Flex>
              <BlockRow marginTop="0.4rem">
                <Text as="span" size="xs" color="gray-darken-2">
                  ※緯度・経度をカンマ区切りで入力してください
                </Text>
              </BlockRow>
              <ErrorText>
                {form.touched.latitudeAndLongitude &&
                  form.errors.latitudeAndLongitude}
              </ErrorText>
            </BlockRow>

            <BlockRow marginTop="1.6rem">
              <BuildingFormLabel htmlFor="correctionRange">
                有効範囲
              </BuildingFormLabel>
              <BlockRow marginTop="0.8rem">
                <div className={styles.modalSelectContainer}>
                  <SelectWrapper
                    inputId="correctionRange"
                    options={options}
                    menuPortalTarget={document.body}
                    menuPlacement="bottom"
                    menuPosition="absolute"
                    menuShouldScrollIntoView={false}
                    value={options.find(
                      (option) => option.value == form.values.correctionRange,
                    )}
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                    }}
                    defaultValue={defaultOption}
                    isSearchable={false}
                    onChange={(option?: OnChangeValue<OptionType, false>) => {
                      form.setFieldValue("correctionRange", option?.value);
                    }}
                  />
                </div>
              </BlockRow>
            </BlockRow>
            <Flex marginTop="3.2rem" justifyContent="center">
              <Button
                className={styles.submitButton}
                type="submit"
                disabled={!form.isValid || form.isSubmitting}
                isLoading={form.isSubmitting}
              >
                登録
              </Button>
            </Flex>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

type Values = {
  name: string;
  latitudeAndLongitude: string;
  correctionRange: number;
};
type UseFormProps = Omit<Props, "isOpen">;
const useForm = ({ sectionId, onClose, building }: UseFormProps) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const mutation = useMutateBuildingApi({ sectionId, building });
  const onSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    const [latitude, longitude] = parseLatitudeAndLongitude(
      values.latitudeAndLongitude,
    );
    const params = {
      name: values.name,
      latitude,
      longitude,
      correction_range: values.correctionRange,
    };

    mutation.mutate(params, {
      onSuccess() {
        showSuccessMessage("位置情報を登録しました");
        formikHelpers.resetForm();
        onClose();
      },
      onError(error) {
        if (error instanceof UnprocessableEntityError) {
          showErrorMessage(error.getErrorMessages().join("\n"));
        } else {
          showErrorMessage("位置情報を登録できませんでした");
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: defaultValues,
    isInitialValid: false,
    validate,
    validationSchema,
    onSubmit,
  });

  React.useEffect(() => {
    building ? formik.setValues(valuesFrom(building)) : formik.resetForm();
  }, [building?.id]);

  return {
    ...formik,
    isSubmitting: mutation.isPending,
  };
};
const valuesFrom = (building: Building) => ({
  name: building.name,
  latitudeAndLongitude: `${building.latitude}, ${building.longitude}`,
  correctionRange: building.correctionRange,
});
const defaultValues = {
  name: "",
  latitudeAndLongitude: "",
  correctionRange: 300,
};
const validate = (values: Values): FormikErrors<Record<string, string>> => {
  const errors: FormikErrors<Record<string, string>> = {};

  const [latitude, longitude, ...rest] = parseLatitudeAndLongitude(
    values.latitudeAndLongitude,
  );

  if (rest.length > 0) {
    errors.latitudeAndLongitude = "緯度・経度の形式が不正です";
  }

  if (Number.isNaN(Number(latitude)) || Number.isNaN(Number(longitude))) {
    errors.latitudeAndLongitude = "緯度・経度は数値で入力してください";
  }

  if (latitude < -90 || latitude > 90) {
    errors.latitudeAndLongitude = "緯度は-90～90の範囲で入力してください";
  }

  if (longitude < -180 || longitude > 180) {
    errors.latitudeAndLongitude = "経度は-180~180の範囲で入力してください";
  }

  return errors;
};
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("場所名を入力してください")
    .max(32, "場所名は32文字以内で入力してください"),
  latitudeAndLongitude: yup
    .string()
    .trim()
    .required("緯度・経度を入力してください"),
  correctionRange: yup
    .number()
    .min(0)
    .max(1000, "有効範囲は1000メートル以内にしてください"),
});

const parseLatitudeAndLongitude = (value: string): number[] => {
  return value
    .trim()
    .split(",")
    .map((str) => parseFloat(str));
};
