import { startOfToday } from "date-fns";
import Loader from "../../components/atoms/Loader";
import { useSingleDateFilterContext } from "../../contexts/SingleDateFilterContext";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";
import { useFetchKoekakeAssists } from "./useFetchKoekakeAssists";

type Props = {
  studentId: string;
};

export const KoekakeAssists = ({ studentId }: Props) => {
  const { date } = useSingleDateFilterContext();
  const datetime = date?.toISOString() ?? startOfToday().toISOString(); // 今日の始まりの日付を設定する

  const { messages, isLoading, error } = useFetchKoekakeAssists({
    studentId,
    datetime,
  });

  useQueryError(error);
  const { sendNotFound } = useErrorDispatcher();
  if (error) {
    sendNotFound();
    return null;
  }

  if (isLoading || messages === undefined) {
    return <Loader loading={isLoading} />;
  }

  return (
    <div className="flex flex-col gap-2 py-4 pl-6">
      {messages.map((message) => (
        <div
          key={message}
          className="flex w-fit items-center rounded-full bg-blue-100 pl-3"
        >
          <span className="m-1">
            <Icon />
          </span>
          <p className="py-1 pl-2 pr-4 text-sm font-bold text-blue-400">
            {message}
          </p>
        </div>
      ))}
    </div>
  );
};

// FIXME: 適切なアイコン名をつけてboron-uiに移動する必要がある
const Icon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>声かけアシストアイコン</title>
      <g clipPath="url(#clip0_9064_3987)">
        <path
          d="M6.5 12H5.5C5.5 8.96 3.04 6.5 0 6.5V5.5C3.04 5.5 5.5 3.04 5.5 0H6.5C6.5 3.04 8.96 5.5 12 5.5V6.5C8.96 6.5 6.5 8.96 6.5 12Z"
          fill="#2F6AED"
        />
      </g>
      <defs>
        <clipPath id="clip0_9064_3987">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
