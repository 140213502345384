import { useQuery, useQueryClient } from "@tanstack/react-query";
import { boronClient } from "../../../../api";
import { createError } from "../../../../errors";
import StaffBoardPostInterface, {
  StaffBoardPostCommentInterface,
} from "../../../../interfaces/StaffBoardPostInterface";

const buildKey = (sectionId: string, staffBoardPostId: string) => [
  "staffBoardPost",
  sectionId,
  staffBoardPostId,
];

export const useFetchStaffBoardPost = ({
  sectionId,
  staffBoardPostId,
  disableCache,
}: {
  sectionId: string;
  staffBoardPostId: string;
  disableCache?: boolean;
}) => {
  return useQuery({
    queryKey: buildKey(sectionId, staffBoardPostId),
    queryFn: async () => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/staff_board_posts/{staff_board_post_hashid}",
        {
          params: {
            path: {
              section_id: sectionId,
              staff_board_post_hashid: staffBoardPostId,
            },
          },
        },
      );
      if (response.ok && data) {
        return data.staffBoardPost;
      }
      throw await createError(response);
    },
    gcTime: disableCache ? 0 : 5 * 60 * 1000, // default 5min
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useAddStaffBoardPostCommentCache = ({
  sectionId,
  staffBoardPostId,
}: {
  sectionId: string;
  staffBoardPostId: string;
}) => {
  const queryClient = useQueryClient();
  const addStaffBoardPostCommentCache = (
    staffBoardPostComment: StaffBoardPostCommentInterface,
  ) => {
    queryClient.setQueryData(
      buildKey(sectionId, staffBoardPostId),
      (data: StaffBoardPostInterface) => {
        return {
          ...data,
          comments: [...data.comments, staffBoardPostComment],
        };
      },
    );
  };

  return { addStaffBoardPostCommentCache };
};

export const useDropStaffBoardPostCommentCache = ({
  sectionId,
  staffBoardPostId,
}: {
  sectionId: string;
  staffBoardPostId: string;
}) => {
  const queryClient = useQueryClient();
  const dropStaffBoardPostCommentCache = (commentId: string) => {
    queryClient.setQueryData(
      buildKey(sectionId, staffBoardPostId),
      (data: StaffBoardPostInterface) => {
        return {
          ...data,
          comments: data.comments.filter((comment) => comment.id !== commentId),
        };
      },
    );
  };

  return { dropStaffBoardPostCommentCache };
};
