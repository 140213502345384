import { StaffBoardPostCommentFormInterface } from "../../../../interfaces/StaffBoardPostInterface";
import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";
import { boronClient } from "../../../../api";

export const usePostStaffBoardPostComment = ({
  sectionId,
  staffBoardPostId,
}: {
  sectionId: string;
  staffBoardPostId: string;
}) => {
  return useBoronMutation(
    async (values: StaffBoardPostCommentFormInterface) =>
      await boronClient.POST(
        "/api/v1/sections/{section_id}/staff_board_posts/{staff_board_post_hashid}/comments",
        {
          params: {
            path: {
              section_id: sectionId,
              staff_board_post_hashid: staffBoardPostId,
            },
          },
          body: {
            staff_board_post_comment: values,
          },
        },
      ),
  );
};
