import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";
import { boronClient } from "../../../../api";

export const useDeleteStaffBoardPost = ({
  sectionId,
  staffBoardPostId,
}: {
  sectionId: string;
  staffBoardPostId: string;
}) => {
  return useBoronMutation(
    async () =>
      await boronClient.DELETE(
        "/api/v1/sections/{section_id}/staff_board_posts/{staff_board_post_hashid}",
        {
          params: {
            path: {
              section_id: sectionId,
              staff_board_post_hashid: staffBoardPostId,
            },
          },
        },
      ),
  );
};
