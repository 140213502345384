import { Field, Form, Formik, FormikProps } from "formik";
import styles from "./styles.scss";
import Checkbox from "../../../../../components/atoms/Checkbox";
import Button, { ButtonSize } from "../../../../../components/atoms/Button";
import { ApiErrorInterface } from "../../../../../interfaces/ApiErrorResponseInterface.ts";
import ErrorText from "../../../../../components/atoms/ErrorText";
import * as React from "react";
import * as yup from "yup";
import { useEffect } from "react";
import { ZoomMeetingSettingInterface } from "../../../../../interfaces/OperatorProfileInterface.ts";

type Props = {
  zoomMeetingSetting?: ZoomMeetingSettingInterface;
  updateZoomMeetingSetting: (params: ZoomMeetingSettingInterface) => void;
  submitting: boolean;
  meetingSettingErrors: ApiErrorInterface[];
};

export const ZoomSettingForm: React.FC<Props> = (props) => {
  const { zoomMeetingSetting, updateZoomMeetingSetting } = props;

  const handleSubmit = async (values: Values) => {
    if (!zoomMeetingSetting) {
      throw new Error("zoomMeetingSettingが作成できていません");
    }

    updateZoomMeetingSetting({
      ...valuesToParams(zoomMeetingSetting.id, values),
    });
  };

  return (
    <Formik
      initialValues={propsToValues(props)}
      initialErrors={undefined}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => <InnerForm {...props} {...formikProps} />}
    </Formik>
  );
};

const InnerForm: React.FC<Props & FormikProps<Values>> = (props) => {
  useEffect(() => props.setSubmitting(props.submitting), [props.submitting]);

  return (
    <Form>
      <div className={styles.field}>
        <Field
          component={Checkbox}
          isBlock={true}
          name="waitingRoom"
          id="waitingRoom"
        >
          待機室を有効にする
        </Field>
      </div>
      <div className={styles.field}>
        <Field
          component={Checkbox}
          isBlock={true}
          name="enabledPassword"
          id="enabledPassword"
        >
          ミーティングパスワードを有効にする
        </Field>
      </div>
      <div className={styles.field}>
        <Field
          component={Checkbox}
          isBlock={true}
          name="joinBeforeHost"
          id="joinBeforeHost"
        >
          ホストより前の参加を有効にする
        </Field>
      </div>
      <div className={styles.field}>
        <Field
          component={Checkbox}
          isBlock={true}
          name="muteUponEntry"
          id="muteUponEntry"
        >
          エントリー時に参加者をミュート
        </Field>
      </div>
      <div className={styles.field}>
        <Field
          component={Checkbox}
          isBlock={true}
          name="autoRecording"
          id="autoRecording"
        >
          ミーティングをローカルコンピューターに自動的にレコーディングする
        </Field>
      </div>
      <div className={styles.submit}>
        <Button
          type="submit"
          disabled={!props.isValid || props.isSubmitting}
          size={ButtonSize.Large}
        >
          更新
        </Button>
      </div>
      {props.meetingSettingErrors.map((error: ApiErrorInterface, i: number) => (
        <ErrorText key={`OperatorZoomForm-meetingSettingError-${i}`}>
          {error.message}
        </ErrorText>
      ))}
    </Form>
  );
};

// connect to Formik

// NOTE: APIだと3値を取れる(Zoomの仕様)が、画面的には録画のON/OFFの2値
type Values = Omit<ZoomMeetingSettingInterface, "id" | "autoRecording"> & {
  autoRecording: boolean;
};

const valuesToParams = (
  id: string,
  values: Values,
): ZoomMeetingSettingInterface => {
  return {
    id,
    ...values,
    autoRecording: values.autoRecording ? "local" : "none",
  };
};

const propsToValues = (props: Props): Values => {
  const defaultValues: Values = {
    waitingRoom: true,
    enabledPassword: true,
    joinBeforeHost: true,
    muteUponEntry: false,
    autoRecording: false,
  };

  return {
    ...defaultValues,
    ...props.zoomMeetingSetting,
    autoRecording: props.zoomMeetingSetting?.autoRecording === "local",
  };
};

const validationSchema = yup.object().shape({
  waitingRoom: yup.boolean(),
  enabledPassword: yup.boolean(),
  joinBeforeHost: yup.boolean(),
  muteUponEntry: yup.boolean(),
  autoRecording: yup.boolean(),
});
