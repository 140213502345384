import * as React from "react";
import styles from "./styles.scss";
import Icon from "../../atoms/Icon";
import DeleteButton from "../DeleteButton/index";
import TimeHelper from "../../../helpers/TimeHelper";
import { RegexpHelper } from "../../../helpers/RegexpHelper";
const reactStringReplace = require("react-string-replace");
const nl2br = require("react-nl2br");

interface Props {
  sentByStudent?: boolean;
  senderName: string;
  comment: string;
  isDeletable?: boolean;
  createdAt: string;
  onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const renderIcon = (sentByStudent?: boolean) => {
  if (sentByStudent) {
    return <Icon name={"icon-navi-avatar"} className={styles.icon} />;
  } else {
    return <Icon name={"icon-account"} className={styles.icon} />;
  }
};

const CommentItem = ({
  senderName,
  sentByStudent,
  comment,
  isDeletable = true,
  createdAt,
  onDelete,
}: Props) => {
  const regexp = RegexpHelper.URL;
  const canDeleteComment = !sentByStudent && isDeletable;

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (window.confirm("このコメントを削除しますか？")) {
      onDelete(e);
    }
  };

  return (
    <div className={styles.root}>
      <div className="flex">
        <div className={styles.left}>{renderIcon(sentByStudent)}</div>
        <div className={styles.right}>
          <div className={styles.senderName}>{senderName}</div>
          <time className={styles.createAt}>
            {TimeHelper.fullFormat(createdAt)}
          </time>
          <p className={styles.body}>
            {nl2br(comment).flatMap((line: string, i: number) => {
              return reactStringReplace(
                line,
                regexp,
                (match: string, n: number) => {
                  return (
                    <a
                      href={match}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={`comment-${createdAt}-link-${i}-${n}`}
                    >
                      {match}
                    </a>
                  );
                },
              );
            })}
          </p>
        </div>
      </div>
      {canDeleteComment && (
        <div className="mt-[0.8rem]">
          <DeleteButton
            ariaLabel={`${senderName}のコメントを削除`}
            tooltipLabel="削除"
            onClick={handleDelete}
            className={styles.deleteButton}
            dataKey={`${createdAt}-${senderName}`}
          />
        </div>
      )}
    </div>
  );
};

export default CommentItem;
