import { useState } from "react";
import { Button, IconTextbook } from "@studyplus/boron-ui";
import enhanceHomePage from "../../hocs/enhanceHomePage";
import OperatorInterface from "../../interfaces/OperatorInterface";
import ContentBox from "../../components/atoms/ContentBox";
import { MultiSectionBatchesDescription } from "../../components/features/MultiSectionBatches/MultiSectionBatchesDescription";
import { MultiSectionBatchesAdminSectionsSelectBox } from "../../components/features/MultiSectionBatches/MultiSectionBatchesAdminSectionsSelectBox";
import { MultiSectionBatchesDownloadDateRangePicker } from "../../components/features/MultiSectionBatches/MultiSectionBatchesDownloadDateRangePicker";
import { useMultiSectionBatchesAdminSectionsSelectBox } from "../../components/features/MultiSectionBatches/useMultiSectionBatchesAdminSectionsSelectBox";
import { useMultiSectionBatchesDateRangePicker } from "../../components/features/MultiSectionBatches/useMultiSectionBatchesDateRangePicker";
import { useMultiSectionBatchesDownload } from "../../hooks/http/useMultiSectionBatchesDownload";
import classNames from "classnames";

type Props = {
  currentOperator: OperatorInterface;
};

const Index = ({ currentOperator }: Props) => {
  const { adminSections, isLoading, hasReachedSectionsLimit, toggleCheckbox } =
    useMultiSectionBatchesAdminSectionsSelectBox({
      operatorId: currentOperator.id,
    });
  const { dateRange, setDateRange, disabled, resetDateRange } =
    useMultiSectionBatchesDateRangePicker();

  const { isPending, mutate } = useMultiSectionBatchesDownload({
    multiSectionBatchType: "download_study_records",
  });

  const [hasSectionsError, setHasSectionsError] = useState(false);
  const [hasDateRangePickerError, setHasDateRangePickerError] = useState(false);

  const createDownloadFile = () => {
    const isSelectedSections = adminSections.some((section) => section.checked);
    setHasSectionsError(!isSelectedSections);

    const isValidDateRange =
      dateRange?.startDate !== undefined && dateRange?.endDate !== undefined;
    setHasDateRangePickerError(!isValidDateRange);

    if (!isSelectedSections || !isValidDateRange) return;

    mutate({
      adminSections,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
  };

  const canCreate = !isLoading && adminSections.length > 0 && !isPending;

  return (
    <div className="flex w-[90%] flex-col">
      <h1 className="text-bold my-10 text-3xl text-black">校舎横断操作</h1>
      <ContentBox className="w-[80%] p-10">
        <div>
          <div className="flex items-center gap-6">
            <IconTextbook className="h-[3rem] w-[3rem] text-gray-600" />
            <h2 className="text-xl">学習記録のダウンロード</h2>
          </div>
          <MultiSectionBatchesDescription
            helpUrl="https://fs-help.studyplus.co.jp/ja/articles/9738631"
            isOnlyProPlanStudent
          />
          <div className="flex flex-col gap-10">
            <MultiSectionBatchesAdminSectionsSelectBox
              hasSectionsError={hasSectionsError}
              isLoading={isLoading}
              adminSections={adminSections}
              hasReachedSectionsLimit={hasReachedSectionsLimit}
              toggleCheckbox={toggleCheckbox}
            />
            <MultiSectionBatchesDownloadDateRangePicker
              dateRange={dateRange}
              disabled={disabled}
              hasDateRangePickerError={hasDateRangePickerError}
              setDateRange={setDateRange}
              resetDateRange={resetDateRange}
            />
            <div className="flex justify-end">
              <Button
                className={classNames({
                  "hover:border-blue-300 hover:bg-blue-300": canCreate,
                })}
                disabled={!canCreate}
                onClick={createDownloadFile}
              >
                作成
              </Button>
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

export const MultiSectionBatchesDownloadStudyRecordsPage = enhanceHomePage(
  Index,
  {
    title: "校舎横断操作",
  },
);
