import { Table, Tr, Th, Td } from "../../components/features/AnalyticsTable";
import TimeHelper, { toTimeString } from "../../helpers/TimeHelper";
import { LocationStatus } from "../../interfaces/StayInterface";
import { useFetchStudentStayAnalytics } from "./useStudentStayAnalyticsApi";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import StudentInterface from "../../interfaces/StudentInterface";
import { AnalyticsTermType } from "../../interfaces/AnalyticsTableInterface";
import Loader from "../../components/atoms/Loader";
import { StudentStayItem } from "./StudentsStayItem";
import styles from "./StayAnalyticsTable.scss";
import ja from "date-fns/locale/ja";
import format from "date-fns/format";

type UseStayAnalyticsTableProps = {
  student: StudentInterface;
  term: AnalyticsTermType;
  endDate: Date;
  enabledRequest: boolean;
};
const useStayAnalyticsTable = ({
  student,
  term,
  endDate,
  enabledRequest,
}: UseStayAnalyticsTableProps) => {
  const {
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    data,
    fetchNextPage,
    error,
  } = useFetchStudentStayAnalytics({
    student,
    startDate: endDate, // API 側は遡って計算するため
    term: term,
    enabledRequest: enabledRequest,
  });
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage]);

  return {
    isLoading,
    data,
  };
};

type Props = UseStayAnalyticsTableProps;
export const StayAnalyticsTable = ({
  student,
  term,
  endDate,
  enabledRequest,
}: Props) => {
  const { isLoading, data: stays } = useStayAnalyticsTable({
    student,
    term,
    endDate,
    enabledRequest,
  });

  if (isLoading || stays == null) return <Loader loading />;

  return (
    <>
      <Table>
        <thead>
          <Tr>
            <Th>日時</Th>
            <Th>校舎名</Th>
            <Th>入室時間</Th>
            <Th>退出時間</Th>
            <Th>滞在時間</Th>
            <Th className="print:hidden"></Th>
          </Tr>
        </thead>
        <tbody>
          {stays.map((stay) => (
            <Tr key={stay.id}>
              <Td>{format(stay.enteredAt, "yyyy/M/d（E）", { locale: ja })}</Td>
              <Td>{stay.enteredSection.sectionName}</Td>
              <Td>
                {toTimeString(stay.enteredAt)}
                {displayLocationStatus(stay.enteredStatus)}
              </Td>
              <Td>
                {stay.exitedAt
                  ? toTimeString(stay.exitedAt) +
                    displayLocationStatus(stay.exitedStatus)
                  : "ー"}
              </Td>
              <Td>
                {stay.staySeconds
                  ? TimeHelper.secondsToDisplayTime(stay.staySeconds)
                  : null}
              </Td>
              <Td className="print:hidden">
                <StudentStayItem
                  sectionId={student.section.id}
                  student={student}
                  stay={stay}
                />
              </Td>
            </Tr>
          ))}
          {stays.length <= 0 ? (
            <tr>
              <td colSpan={6}>
                <p className={styles.notFound}>
                  入退室記録が見つかりませんでした
                </p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </>
  );
};

// Helpers
const displayLocationStatus = (status: LocationStatus | null) => {
  switch (status) {
    case "inside":
      return "（範囲内）";
    case "outside":
      return "（範囲外）";
    case "indeterminable":
      return "（判定不可）";
    case "unable_to_locate":
      return "（取得不可）";
    default:
      return "";
  }
};
