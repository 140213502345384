import * as React from "react";

import { createContext } from "../helpers/React";
import { startOfDay } from "date-fns";

const { useContext, Provider } =
  createContext<ReturnType<typeof useSingleDateFilter>>();

// 単一の日付の選択状態をグローバルに管理するhooks
const useSingleDateFilter = () => {
  const [date, setDate] = React.useState<Date>();

  const setStartOfDate = (date: Date) => {
    const startOfDate = startOfDay(date);
    setDate(startOfDate);
  };

  // 今日の始まりの日付を設定する
  const setStartOfToday = () => {
    const startOfToday = startOfDay(new Date());
    setDate(startOfToday);
  };

  return {
    date,
    setStartOfDate,
    setStartOfToday,
  };
};

export const useSingleDateFilterContext = useContext;

export const SingleDateFilterProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <Provider value={useSingleDateFilter()}>{children}</Provider>;
};
