import * as React from "react";
import styles from "./styles.scss";
import Select from "react-select";
import { HandleThunkActionCreator } from "react-redux";
import StudentInterface from "../../../interfaces/StudentInterface";
import BackLink from "../../atoms/BackLink";
import { Button } from "@studyplus/boron-ui";
import {
  LearningMaterialSearchType,
  SearchLearningMaterialParams,
} from "../../../api-clients/SectionLearningMaterialApi";
import { dispatchRegisterLearningMaterialToBookshelf } from "../../../actions/pages/studentsLearningMaterialsNew/index";
import StudentsLearningMaterialsNewStateInterface from "../../../interfaces/StudentsLearningMaterialsNewStateInterface";
import Loader from "../../atoms/Loader/index";
import * as queryString from "query-string";
import LearningMaterialEntityInterface from "../../../interfaces/LearningMaterialEntityInterface";
import { Link } from "react-router-dom";
import { dispatchPreRegisterLearningMaterialToBookshelf } from "../../../actions/pages/StudentsLearningMaterialsPreRegistrationsNewPage/index";
import StudentsLearningMaterialsStateInterface from "../../../interfaces/StudentsLearningMaterialsStateInterface";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import { PopoverProvider } from "../../general/Popover/Popover";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "../../general/Popover/Popover";

interface Props extends WithRouterProps {
  student: StudentInterface;
  learningMaterialsState: StudentsLearningMaterialsNewStateInterface;
  studentLearningMaterialsState: StudentsLearningMaterialsStateInterface;
  defaultSearchText: string;
  defaultSearchType: LearningMaterialSearchType;
  onRegister: HandleThunkActionCreator<
    | typeof dispatchRegisterLearningMaterialToBookshelf
    | typeof dispatchPreRegisterLearningMaterialToBookshelf
  >;
  isPreRegistration?: boolean;
}

interface State {
  searchText: string;
  searchType: LearningMaterialSearchType;
  submittingLearningMaterials: LearningMaterialEntityInterface[];
}

interface SearchTypeOption {
  label: string;
  value: LearningMaterialSearchType;
}

class StudentNewLearningMaterial extends React.Component<Props, State> {
  SEARCH_TYPE_OPTIONS: SearchTypeOption[] = [
    {
      label: "校舎独自教材",
      value: "section_original",
    },
    {
      label: "デジタル教材",
      value: "drill",
    },
    {
      label: "書籍",
      value: "general_books",
    },
    {
      label: "App Store",
      value: "itunes_app",
    },
  ];
  private searchTypeRef =
    React.createRef() as React.MutableRefObject<LearningMaterialSearchType>;

  constructor(props: Props) {
    super(props);
    this.state = {
      searchText: props.defaultSearchText,
      searchType: props.defaultSearchType,
      submittingLearningMaterials: [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.learningMaterialsState.submitting &&
      !this.props.learningMaterialsState.submitting
    ) {
      this.setState({
        submittingLearningMaterials: [],
      });
    }
  }

  render() {
    return (
      <div className={styles.root}>
        <BackLink path={this.indexPath()}>戻る</BackLink>

        {/* 検索フォーム  */}
        <form
          className={styles.searchForm}
          onSubmit={this.handleSearch}
          aria-label="教材検索"
        >
          <div className={styles.searchForm__type}>
            <label htmlFor="StudentNewLearningMaterial-type">教材種別</label>
            <Select
              inputId="StudentNewLearningMaterial-type"
              options={this.SEARCH_TYPE_OPTIONS}
              className={styles.searchForm__type__select}
              defaultValue={this.SEARCH_TYPE_OPTIONS[0]}
              isSearchable={false}
              styles={{
                control: (styles: any) => ({
                  ...styles,
                  height: "4.6rem",
                }),
              }}
              onChange={this.handleChangeSearchType}
              value={this.SEARCH_TYPE_OPTIONS.find(
                (option) => option.value === this.state.searchType,
              )}
            />
          </div>

          <div className={styles.searchForm__name}>
            <label htmlFor="StudentNewLearningMaterial-name">教材名</label>
            <input
              id="StudentNewLearningMaterial-name"
              name="name"
              value={this.state.searchText}
              onChange={this.handleChangeSearchText}
            />
          </div>

          <div className={styles.searchForm__button}>
            <Button
              disabled={
                this.props.learningMaterialsState.loading ||
                !(this.state.searchText.length > 0)
              }
              isBlock={true}
              type="submit"
            >
              検索
            </Button>
          </div>
        </form>

        {/* 検索結果  */}
        {this.renderSearchResult()}
        <Loader
          loading={
            this.props.learningMaterialsState.loading ||
            this.props.studentLearningMaterialsState.loading
          }
        />
      </div>
    );
  }

  private renderSearchResult() {
    const { learningMaterialsState, studentLearningMaterialsState } =
      this.props;

    if (learningMaterialsState.data && studentLearningMaterialsState.data) {
      return (
        <>
          {/* 検索結果件数 */}
          <div className={styles.searchResultCount}>
            <strong>
              検索結果：{learningMaterialsState.meta.totalCount}件
            </strong>
          </div>

          {/* 検索結果一覧  */}
          {learningMaterialsState.data.length > 0 ? (
            <div className={styles.searchResult}>
              <ul>
                {this.renderLearningMaterials(learningMaterialsState.data)}
              </ul>
            </div>
          ) : this.searchTypeRef.current !== "drill" ? (
            <p className={`mt-7 ${styles.errorMessage}`}>
              該当するテキストが見つかりませんでした。教材を新しく登録もできます。
              <Link
                to={`/sections/${this.props.student.section.id}/settings/section_learning_materials/new`}
                className={styles.linkInEmptyResultMessage}
              >
                独自教材の登録はこちら
              </Link>
            </p>
          ) : (
            <p className="mt-7">該当する教材が見つかりませんでした。</p>
          )}
        </>
      );
    } else {
      return null;
    }
  }

  private renderLearningMaterials(
    learningMaterials: LearningMaterialEntityInterface[],
  ) {
    return learningMaterials.map((learningMaterial) => {
      return this.renderLearningMaterial(learningMaterial);
    });
  }

  private renderLearningMaterial(
    learningMaterial: LearningMaterialEntityInterface,
  ) {
    const { studentLearningMaterialsState } = this.props;
    if (!studentLearningMaterialsState.data) return null;
    return (
      <li
        className={styles.searchResult__learningMaterial}
        key={`learning_material-${learningMaterial.code}`}
      >
        <img src={learningMaterial.imageUrl} />
        <h3 className={styles.searchResult__learningMaterial__name}>
          {learningMaterial.title}
        </h3>
        {learningMaterial.isAssigned ||
        studentLearningMaterialsState.data.some(
          (studentLearningMaterial: LearningMaterialEntityInterface) => {
            return studentLearningMaterial.code === learningMaterial.code;
          },
        ) ? (
          <Button
            disabled={true}
            className={styles.searchResult__learningMaterial__button}
          >
            登録済
          </Button>
        ) : !learningMaterial.isBookshelfRegistrable ? (
          <PopoverProvider>
            <Popover>
              <PopoverTrigger asChild className="cursor-not-allowed">
                <span className="w-full sm:w-auto">
                  <Button
                    disabled={true}
                    className={`pointer-events-none ${styles.searchResult__learningMaterial__button}`}
                  >
                    配信枠終了
                  </Button>
                </span>
              </PopoverTrigger>
              <PopoverPortal>
                <PopoverContent side="top">
                  <div className="text-center text-sm font-normal">
                    生徒配信枠利用数の
                    <br />
                    上限に達しています
                  </div>
                </PopoverContent>
              </PopoverPortal>
            </Popover>
          </PopoverProvider>
        ) : (
          <Button
            disabled={this.state.submittingLearningMaterials.some(
              (lm) => lm.code === learningMaterial.code,
            )}
            variant="outline"
            className={styles.searchResult__learningMaterial__button}
            onClick={this.handleRegisterLearningMaterial(learningMaterial)}
          >
            本棚に登録
          </Button>
        )}
      </li>
    );
  }

  private handleRegisterLearningMaterial =
    (learningMaterial: LearningMaterialEntityInterface) => () => {
      if (!this.props.student) {
        return;
      }
      if (learningMaterial.isTypeDrill) {
        if (
          !window.confirm(
            `この教材が有償教材の場合、本棚登録をしたタイミングで利用料が発生します。詳しくは、デジタル教材に関する注意事項をご確認ください。\n※無償教材の場合、利用料は発生しません。\n\n「${this.props.student.fullName}」の本棚に「${learningMaterial.title}」を登録しますか？`,
          )
        ) {
          return;
        }
      }

      this.setState({
        submittingLearningMaterials:
          this.state.submittingLearningMaterials.concat(learningMaterial),
      });

      this.props.onRegister(this.props.student.id, learningMaterial.code);
    };

  private handleChangeSearchText = (e: any) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  private handleSearch = (e: any) => {
    e.preventDefault();

    const params: SearchLearningMaterialParams = {
      keyword: this.state.searchText,
      type: this.state.searchType,
    };

    const paramsString = queryString.stringify(params);
    this.props.navigate({ search: `?${paramsString}` });

    this.searchTypeRef.current = this.state.searchType;
  };

  // FIXME: @types/react-selectの型が誤っているため,
  // option.valueを参照できるようにするため option:any にしています
  // ref: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32553
  private handleChangeSearchType = (option: any) => {
    this.setState({
      searchType: option.value,
    });
  };

  private indexPath(): string {
    const { student } = this.props;

    if (this.props.isPreRegistration) {
      return `/students/${student.id}/learning_materials/pre_registrations`;
    } else {
      return `/students/${student.id}/learning_materials`;
    }
  }
}

export default withRouter<Props>(StudentNewLearningMaterial);
