import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";
import { StaffBoardPostFormInterface } from "../../../../interfaces/StaffBoardPostInterface";
import { boronClient } from "../../../../api";

export const useUpdateStaffBoardPost = ({
  sectionId,
  staffBoardPostId,
}: {
  sectionId: string;
  staffBoardPostId: string;
}) => {
  return useBoronMutation(
    async (values: StaffBoardPostFormInterface) =>
      await boronClient.PATCH(
        "/api/v1/sections/{section_id}/staff_board_posts/{staff_board_post_hashid}",
        {
          params: {
            path: {
              section_id: sectionId,
              staff_board_post_hashid: staffBoardPostId,
            },
          },
          body: {
            staff_board_post: values,
          },
        },
      ),
  );
};
