import * as React from "react";
import OperatorInterface from "../../../../interfaces/OperatorInterface";
import SectionInterface from "../../../../interfaces/SectionInterface";
import Loader from "../../../../components/atoms/Loader";
import StaffBoardPostForm from "../../../../components/molecules/StaffBoardPostForm";
import { StaffBoardPostFormInterface } from "../../../../interfaces/StaffBoardPostInterface";
import BackLink from "../../../../components/atoms/BackLink";
import { useNavigate } from "react-router-dom";
import { useFetchStaffBoardPost } from "../../SectionsStaffBoardPostPage/SectionStaffBoardPost/useFetchStaffBoardPost";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { FSHTTPError } from "../../../../errors";
import { HTTP_ERROR_MESSAGE } from "../../../../reducers";
import { useUpdateStaffBoardPost } from "./useUpdateStaffBoardPost";

interface Props {
  section: SectionInterface;
  currentOperator: OperatorInterface;
  staffBoardPostId: string;
}

const SectionsStaffBoardPostEdit = (props: Props) => {
  const { section, currentOperator, staffBoardPostId } = props;

  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { isPending, data, error } = useFetchStaffBoardPost({
    sectionId: section.id,
    staffBoardPostId,
    disableCache: true, // キャッシュがあると、フォームに変更前の内容が表示されることがあるので無効化する
  });
  useQueryError(error);

  const { mutate, isPending: isSubmitting } = useUpdateStaffBoardPost({
    sectionId: section.id,
    staffBoardPostId,
  });
  const handleSubmit = (values: StaffBoardPostFormInterface) => {
    mutate(values, {
      onSuccess: () => {
        showSuccessMessage("スタッフボードを更新しました");
        navigate(`/sections/${section.id}/staff_board_posts`);
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage("スタッフボードを更新できませんでした");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    });
  };

  return (
    <React.Fragment>
      <BackLink>戻る</BackLink>
      {isPending || !data ? (
        <Loader loading />
      ) : (
        <StaffBoardPostForm
          staffBoardPost={data}
          submitting={isSubmitting}
          operatorName={currentOperator.fullName}
          onSubmit={handleSubmit}
        />
      )}
    </React.Fragment>
  );
};

export default SectionsStaffBoardPostEdit;
