import Settings from "../../../../helpers/Settings.ts";
import { useFlashMessage } from "../../../../hooks/useFlashMessage.ts";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { boronClient } from "../../../../api.ts";
import { useBoronMutation } from "../../../../hooks/http/useBoronMutation.ts";
import { useUpdateProfileCache } from "../../../../hooks/http/useFetchProfile.ts";

export const useGoogleClassroomConnection = ({
  operatorId,
  isPending,
  setIsPendingExternalConnection,
}: {
  operatorId: string;
  isPending: boolean;
  setIsPendingExternalConnection: (isLoading: boolean) => void;
}) => {
  const { showSuccessMessage, showErrorMessage, hideFlashMessage } =
    useFlashMessage();
  const [searchParams] = useSearchParams();

  const connectGoogleClassroom = () => {
    if (isPending) return;

    setIsPendingExternalConnection(true);
    hideFlashMessage();
    window.location.href = `${Settings.BORON_URL}/google/oauth?operator_id=${operatorId}`;
  };

  const connectGoogleClassroomError = () => {
    setIsPendingExternalConnection(false);
    showErrorMessage("GoogleClassroom連携に失敗しました");
  };

  const { mutateAsync } = useBoronMutation(
    async () =>
      await boronClient.DELETE("/api/v1/google_classroom/deauthorize"),
  );

  const { updateProfileCache } = useUpdateProfileCache();

  const disconnectGoogleClassroom = async () => {
    if (
      confirm(
        "Google Classroomとの連携を本当に解除してよろしいですか？連携解除を実行すると、本アカウントで同期を実行した全てのGoogle ClassroomクラスとStudyplus for Schoolの同期が停止されます。",
      )
    ) {
      await mutateAsync(undefined, {
        onSuccess: () => {
          updateProfileCache({
            googleClassroomConnected: false,
            googleUserProfile: null,
          });
          showSuccessMessage("GoogleClassroom連携を解除しました");
        },
        onError: () => {
          showErrorMessage("GoogleClassroom連携の解除に失敗しました");
        },
      });
    }
  };

  const switchGoogleClassroomConnection = async (
    value: boolean,
  ): Promise<boolean> => {
    if (value) {
      connectGoogleClassroom();
    } else {
      await disconnectGoogleClassroom();
    }

    return true;
  };

  useEffect(() => {
    searchParams.get("auth_google") == "failed" &&
      connectGoogleClassroomError();
  }, [searchParams]);

  return {
    switchGoogleClassroomConnection,
  };
};
