import { OperatorRole } from "../../interfaces/OperatorInterface";
import StudentInterface from "../../interfaces/StudentInterface";
import Loader from "../../components/atoms/Loader";
import { StudentItem } from "./StudentItem";
import styles from "./SectionsSettingsStudentsIndex.scss";
import SortLabel, {
  OrderDirectionType,
} from "../../components/molecules/SortLabel";
import { OrderDirFilterType } from "../../interfaces/FiltersInterface";

interface Props {
  guardianLineConnectionUsable: boolean;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  students: readonly StudentInterface[];
  updateStudent: (student: StudentInterface) => void;
  removeStudent: (studentId: string) => void;
  operatorRole: OperatorRole;
  orderDir: OrderDirFilterType;
  onOrderChange: (orderDir: OrderDirFilterType) => void;
}

const SectionSettingsStudentsIndex = (props: Props) => {
  const { isLoading, isFetchingNextPage } = props;
  return (
    <div className={styles.root}>
      <StudentsTable {...props} />
      <Loader loading={isLoading || isFetchingNextPage} />
    </div>
  );
};

const StudentsTable = (props: Props) => {
  const { isLoading, students } = props;
  const isGuardianEmailVisible = props.operatorRole !== "limited_member";

  if (isLoading) {
    return null;
  }

  if (students.length <= 0) {
    return <p>生徒が登録されていません</p>;
  }

  const handleSortClick = () => {
    const orderDir =
      props.orderDir === "" || props.orderDir === "desc" ? "asc" : "desc";
    props.onOrderChange(orderDir);
  };

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <SortLabel
                label="生徒ID"
                active={!!props.orderDir}
                orderDirection={
                  (props.orderDir as OrderDirectionType) || "desc"
                }
                onClick={handleSortClick}
              />
            </th>
            <th>氏名</th>
            <th>学年（職業）</th>
            <th>利用プラン</th>
            <th colSpan={2}>生徒アプリ連携コード</th>
            <th>生徒用入退室QR</th>
            {isGuardianEmailVisible && <th colSpan={2}>保護者メール登録</th>}
            {props.guardianLineConnectionUsable && (
              <th colSpan={2}>保護者LINE連携QR</th>
            )}
            <th></th>
          </tr>
        </thead>

        <tbody>
          {students.map((student: StudentInterface, i: number) => {
            return (
              <StudentItem
                key={`SectionSettingsStudentsIndex-StudentData-${student.id}`}
                index={i}
                studentsSize={students.length}
                student={student}
                updateStudent={props.updateStudent}
                removeStudent={props.removeStudent}
                operatorRole={props.operatorRole}
                guardianLineConnectionUsable={
                  props.guardianLineConnectionUsable
                }
                isGuardianEmailVisible={isGuardianEmailVisible}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SectionSettingsStudentsIndex;
