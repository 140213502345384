import * as React from "react";
import { useEffect, useState } from "react";
import styles from "./styles.scss";
import OperatorInterface from "../../../../interfaces/OperatorInterface.ts";
import OperatorProfileNameForm from "./OperatorProfileNameForm";
import OperatorProfileEmailForm from "./OperatorProfileEmailForm";
import OperatorProfilePasswordForm from "./OperatorProfilePasswordForm";
import BackLink from "../../../../components/atoms/BackLink";
import OperatorZoomForm from "./OperatorZoomForm";
import OperatorGoogleClassroomForm from "./OperatorGoogleClassroomForm";
import Loader from "../../../../components/atoms/Loader";
import { useUpdateOperatorName } from "./useUpdateOperatorName.tsx";
import { useUpdateOperatorEmail } from "./useUpdateOperatorEmail.tsx";
import { useUpdateOperatorPassword } from "./useUpdateOperatorPassword.tsx";
import { useGoogleClassroomConnection } from "./useGoogleClassroomConnection.tsx";
import { useZoomConnection } from "./useZoomConnection.tsx";
import { useUpdateZoomMeetingSetting } from "./useUpdateZoomMeetingSetting.tsx";
import { useFetchProfile } from "../../../../hooks/http/useFetchProfile.ts";

type Props = {
  operator: OperatorInterface;
};
const SettingsProfileEdit = (props: Props) => {
  const [isPendingExternalConnection, setIsPendingExternalConnection] =
    useState(false);

  const {
    data: operatorProfile,
    // アカウント切り替え時にアカウントとプロフィールのずれをなくすためローダー表示にしたいので、isPendingではなくisFetchingを使う
    isFetching: isFetchingProfile,
    refetch: refetchProfile,
  } = useFetchProfile();

  useEffect(() => {
    // パスワード変更時に自動ログアウトされアカウントが変更されることがあるため、再度プロフィールを取得する
    refetchProfile();
  }, [props.operator.id]);

  const {
    updateOperatorName,
    isPending: isPendingUpdateName,
    apiErrors: nameApiErrors,
  } = useUpdateOperatorName();

  const {
    updateOperatorEmail,
    isPending: isPendingUpdateEmail,
    apiErrors: emailApiErrors,
  } = useUpdateOperatorEmail();

  const {
    updateOperatorPassword,
    isPending: isPendingUpdatePassword,
    apiErrors: passwordApiErrors,
  } = useUpdateOperatorPassword();

  const {
    isPending: isPendingZoomMeetingSetting,
    updateZoomMeetingSetting,
    apiErrors: zoomMeetingSettingErrors,
  } = useUpdateZoomMeetingSetting();

  const isPending =
    isPendingUpdateName ||
    isPendingUpdateEmail ||
    isPendingUpdatePassword ||
    isPendingZoomMeetingSetting ||
    isPendingExternalConnection;

  const { switchGoogleClassroomConnection } = useGoogleClassroomConnection({
    operatorId: props.operator.id,
    isPending,
    setIsPendingExternalConnection,
  });

  const { switchZoomConnection } = useZoomConnection({
    operatorId: props.operator.id,
    isPending,
    setIsPendingExternalConnection,
  });

  if (isFetchingProfile || !operatorProfile) {
    return <Loader loading={true} />;
  }

  return (
    <React.Fragment>
      <div className={styles.backlink}>
        <BackLink path="/sections/">校舎一覧へ</BackLink>
      </div>
      <div className={styles.root}>
        <h2 className={styles.title}>アカウント設定</h2>
        <div>
          <OperatorProfileNameForm
            operator={props.operator}
            submitting={isPending}
            apiErrors={nameApiErrors}
            updateName={updateOperatorName}
          />
          <OperatorProfileEmailForm
            operator={props.operator}
            submitting={isPending}
            apiErrors={emailApiErrors}
            updateEmail={updateOperatorEmail}
          />
          <OperatorProfilePasswordForm
            submitting={isPending}
            apiErrors={passwordApiErrors}
            updatePassword={updateOperatorPassword}
          />
          <h2 className={styles.title}>Zoom連携</h2>
          <p className={styles.description}>
            Zoom連携をするとメッセージ画面でZoom
            URLの自動発行・送付ができるようになります。
          </p>
          <OperatorZoomForm
            connected={operatorProfile.zoomConnected}
            email={operatorProfile.zoomProfile?.email ?? undefined}
            zoomMeetingSetting={operatorProfile.zoomMeetingSetting ?? undefined}
            onSwitch={switchZoomConnection}
            updateZoomMeetingSetting={updateZoomMeetingSetting}
            submitting={isPending}
            meetingSettingErrors={zoomMeetingSettingErrors}
          />
          <h2 className={styles.title}>Google Classroom連携</h2>
          <p className={styles.description}>
            Google
            Classroom連携をすると生徒から提出された課題がタイムラインに投稿されるようになります。
          </p>
          <OperatorGoogleClassroomForm
            connected={operatorProfile.googleClassroomConnected}
            googleUserProfile={operatorProfile.googleUserProfile ?? undefined}
            onSwitch={switchGoogleClassroomConnection}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SettingsProfileEdit;
