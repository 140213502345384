import * as React from "react";
import Icon from "../../../../../components/atoms/Icon";
import styles from "./styles.scss";

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const OperatorHelpLink: React.FC<Props> = ({
  children,
  ...props
}: Props) => (
  <a
    {...props}
    target="_blank"
    rel="noopener noreferrer"
    className={styles.link}
  >
    <Icon name="icon-help" />
    {children}
  </a>
);
