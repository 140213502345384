import * as React from "react";
import styles from "./styles.scss";
import Icon from "../../atoms/Icon";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "../../general/Tooltip/Tooltip";

interface Props extends React.PropsWithChildren<any> {
  tooltipLabel: string;
  onClick: (e: any) => void;
  className?: string;
  dataKey?: string;
  ariaLabel?: string;
}

const attachParentClassName = (className?: string) => {
  if (className) {
    return `${styles.root} ${className}`;
  } else {
    return styles.root;
  }
};

const DeleteButton = ({
  tooltipLabel,
  onClick,
  className,
  dataKey,
  ariaLabel,
}: Props) => {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            className={attachParentClassName(className)}
            type="button"
            onClick={onClick}
            data-for={`comment-delete-btn_${dataKey}`}
            data-tip={true}
            aria-label={ariaLabel}
          >
            <Icon name="icon-trash" />
          </button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>
            <p>{tooltipLabel}</p>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};

export default DeleteButton;
