// FSでサポートしているブラウザ
// See: https://fs-help.studyplus.co.jp/ja/articles/3273850-%E6%8E%A8%E5%A5%A8%E7%92%B0%E5%A2%83%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6

const isUserAgentChrome = () => {
  return navigator.userAgent.toLowerCase().includes("chrome");
};

const isUserAgentSafari = () => {
  return navigator.userAgent.toLowerCase().includes("safari");
};
const isUserAgentFirefox = () => {
  return navigator.userAgent.toLowerCase().includes("firefox");
};
const isUserAgentEdge = () => {
  return navigator.userAgent.toLowerCase().includes("edg");
};

const isTargetBrowser = (browsers?: Browser[]): boolean => {
  if (!browsers || browsers.length === 0) {
    return true;
  }
  if (browsers.includes("chrome") && isUserAgentChrome()) {
    return true;
  } else if (browsers.includes("safari") && isUserAgentSafari()) {
    return true;
  } else if (browsers.includes("firefox") && isUserAgentFirefox()) {
    return true;
  } else if (browsers.includes("edg") && isUserAgentEdge()) {
    return true;
  }
  return false;
};

export type Browser = "chrome" | "safari" | "firefox" | "edg";

const BrowserHelper = {
  isTargetBrowser,
};

export default BrowserHelper;
