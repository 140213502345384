import { useBoronMutation } from "../../../../hooks/http/useBoronMutation.ts";
import { boronClient } from "../../../../api.ts";
import { UnprocessableEntityError } from "../../../../errors.ts";
import { useFlashMessage } from "../../../../hooks/useFlashMessage.ts";
import { useFetchAndUpdateUserInfo } from "./useFetchAndUpdateUserInfo.tsx";

export const useUpdateOperatorEmail = () => {
  const { fetchAndUpdateUserInfo } = useFetchAndUpdateUserInfo();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const mutation = useBoronMutation(
    async ({ email }: { email: string }) =>
      await boronClient.PATCH("/api/v1/settings/email", {
        body: {
          operator_email_confirmation_token: { email },
        },
      }),
  );

  const updateOperatorEmail = (email: string) => {
    mutation.mutate(
      { email },
      {
        onSuccess: async () => {
          // reduxのアカウント情報を更新
          await fetchAndUpdateUserInfo();
          showSuccessMessage(
            "メールアドレスの更新のため、メールを送信しました",
          );
        },
        onError: (error) => {
          if (error instanceof UnprocessableEntityError) {
            showErrorMessage(
              "確認メールの送信に失敗しました。登録済みのメールアドレスには変更できません",
            );
          } else {
            showErrorMessage("メールアドレスが更新できませんでした");
          }
        },
      },
    );
  };

  const apiErrors =
    mutation.error instanceof UnprocessableEntityError
      ? mutation.error.originalErrors
      : [];
  return { ...mutation, updateOperatorEmail, apiErrors };
};
