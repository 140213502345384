import * as React from "react";
import { connect, HandleThunkActionCreator } from "react-redux";

import {
  loadFollowStatus,
  loadInitialStudentMessages,
  loadMoreStudentMessages,
  deleteMessage,
} from "../../../actions/pages/studentMessages";
import TabsNavi from "../../../components/molecules/TabsNavi";
import StudentMessage from "../../../components/organisms/StudentMessage";
import enhanceStudentsPage from "../../../hocs/enhanceStudentsPage";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { StudentMessagesStateInterface } from "../../../interfaces/MessageInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../../hocs/enhanceAuthenticatedPage";
import { useEffect } from "react";
import { useFetchProfile } from "../../../hooks/http/useFetchProfile.ts";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    OutletContextProps {
  student: StudentInterface | null;
  messagesState: StudentMessagesStateInterface;
  loadFollowStatus: (studentId: string) => void;
  loadInitialStudentMessages: (studentId: string) => void;
  loadMoreStudentMessages: (studentId: string, since: string) => void;
  currentOperator: OperatorInterface;
  deleteMessage: HandleThunkActionCreator<typeof deleteMessage>;
}

const StudentsMessagesPage: React.FC<Props> = (props) => {
  const { studentId } = props.params;

  useEffect(() => {
    props.setActiveMenu("message");
  }, []);

  useEffect(() => {
    props.loadInitialStudentMessages(studentId);
    props.loadFollowStatus(studentId);
  }, [studentId]);

  const { student, messagesState, location } = props;

  const { data: operatorProfile } = useFetchProfile();

  return (
    <>
      <div style={{ marginTop: "-1.0rem" }}>
        <TabsNavi
          tabs={[
            {
              label: "生徒",
              link: {
                pathname: `/students/${props.params.studentId}/messages`,
                search: location.search,
              },
              active: true,
            },
            {
              label: "保護者",
              link: {
                pathname: `/students/${props.params.studentId}/guardian_messages`,
                search: location.search,
              },
              active: false,
            },
          ]}
        />
      </div>
      {student && messagesState.isFollowing !== null && (
        <StudentMessage
          student={student}
          isFollowing={messagesState.isFollowing}
          messagesState={messagesState}
          operator={props.currentOperator}
          operatorProfile={operatorProfile ?? null}
          deleteMessage={props.deleteMessage}
        />
      )}
    </>
  );
};

const pageInfo = {
  title: "メッセージ",
};

const EnhancedPage = enhanceStudentsPage(StudentsMessagesPage, pageInfo);

const mapStateToProps = (state: AppStateInterface) => {
  return {
    messagesState: state.studentMessagesState,
  };
};

const actions = {
  loadInitialStudentMessages,
  loadMoreStudentMessages,
  loadFollowStatus,
  deleteMessage,
};

export default connect(mapStateToProps, actions)(EnhancedPage as any);
