import * as React from "react";
import {
  Chart,
  ChartData,
  ChartOptions,
  registerables,
  TickOptions,
  TooltipCallbacks,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styleVars from "../../../styles/variables.scss";
import { useEffectAvoidFirst } from "../../../hooks/useEffectAvoidFirst";
import styles from "./AnalyticsBarChart.scss";
import BrowserHelper from "../../../helpers/BrowserHelper";

// Main hooks
type UseAnalyticsChartProps = {
  labels: ChartData<"bar">["labels"] | null;
  dataSets: ChartData<"bar">["datasets"] | null;
  chartOptions?: ChartOptions<"bar">;
};
const useAnalyticsBarChart = ({
  labels,
  dataSets,
  chartOptions,
}: UseAnalyticsChartProps) => {
  const chartRef = React.useRef<Chart>();
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const mediaQueryListRef = React.useRef<MediaQueryList>();

  React.useLayoutEffect(() => {
    const ctx = canvasRef.current?.getContext("2d");
    Chart.register(...registerables);

    if (ctx && labels && dataSets && chartOptions) {
      chartRef.current = new Chart(ctx, {
        plugins: [ChartDataLabels],
        type: "bar",
        options: chartOptions,
        data: {
          labels: labels,
          datasets: dataSets,
        },
      });
    }
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [canvasRef.current]);

  useEffectAvoidFirst(() => {
    if (chartRef.current && dataSets) {
      chartRef.current.data = { ...chartRef.current.data, datasets: dataSets };
      chartRef.current.update();
    }
  }, [chartRef.current, dataSets]);

  const handleBeforePrint = (e: MediaQueryListEvent) => {
    if (!chartRef.current) return;
    // Firefoxは何もしなくても表示崩れしないので何もしない
    if (BrowserHelper.isTargetBrowser(["firefox"])) return;

    if (e.matches) {
      // NOTE: 印刷時はグラフサイズをレスポンシブにしないことで表示崩れを防ぐ
      chartRef.current.options.responsive = false;
      chartRef.current.canvas.style.width = "100%";
      chartRef.current.update();
    } else {
      chartRef.current.options.responsive = true;
      chartRef.current.update();
    }
  };

  React.useEffect(() => {
    if (!mediaQueryListRef.current) {
      mediaQueryListRef.current = window.matchMedia("print");
      mediaQueryListRef.current.addEventListener("change", handleBeforePrint);
    }
    return () => {
      mediaQueryListRef.current?.removeEventListener(
        "change",
        handleBeforePrint,
      );
    };
  }, []);

  return { canvasRef };
};

export type BarChartProps = {
  labels: ChartData<"bar">["labels"];
  dataSets: ChartData<"bar">["datasets"];
  tooltipLabelCallback?: TooltipCallbacks<"bar">["label"];
  yTickCallback?: TickOptions["callback"];
};

const lineColor = "#e7e9ee";

export const AnalyticsBarChart = ({
  labels,
  dataSets,
  tooltipLabelCallback,
  yTickCallback,
}: BarChartProps) => {
  const { canvasRef } = useAnalyticsBarChart({
    labels: labels,
    dataSets: dataSets,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          formatter: () => "",
        },
        tooltip: {
          mode: "index",
          padding: {
            top: 10,
            bottom: 10,
            left: 16,
            right: 16,
          },
          backgroundColor: styleVars.colorBlackLighten1,
          cornerRadius: 4,
          caretSize: 10,
          callbacks: {
            label: tooltipLabelCallback,
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            padding: 8,
            color: styleVars.colorGrayDarken3,
            font: {
              weight: "bold",
            },
          },
          grid: {
            display: false,
          },
          border: {
            display: false, // NOTE: これがないと横軸の線が2重に表示されてしまう
          },
        },
        y: {
          stacked: true,
          beginAtZero: true,
          min: 0,
          max: undefined,
          ticks: {
            stepSize: 3600,
            callback: yTickCallback,
            color: styleVars.colorBlackLighten2,
            padding: 10,
          },
          border: {
            display: false,
          },
          grid: {
            color: lineColor,
            lineWidth: function (context) {
              return context.index == 0 ? 2 : 1;
            },
          },
        },
      },
    },
  });

  return (
    <figure className={styles.Wrapper}>
      <canvas ref={canvasRef} />
    </figure>
  );
};
