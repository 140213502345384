import * as React from "react";
import styles from "./styles.scss";
import OperatorInterface from "../../../../../interfaces/OperatorInterface.ts";
import {
  FormikProps,
  FormikBag,
  withFormik,
  Form,
  Field,
  ErrorMessage,
} from "formik";
import Input from "../../../../../components/atoms/Input";
import Label from "../../../../../components/atoms/Label";
import { Modal } from "../../../../../components/atoms/Modal";
import Button from "../../../../../components/atoms/Button";
import validationSchema from "./validationSchema.ts";
import { ApiErrorInterface } from "../../../../../interfaces/ApiErrorResponseInterface.ts";
import ErrorText from "../../../../../components/atoms/ErrorText";

interface ComponentProps {
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
  operator: OperatorInterface;
  updateEmail: (email: string) => void;
}
interface Values {
  email: string;
}

interface State {
  isOpen: boolean;
}

type Props = ComponentProps & FormikProps<Values>;

class OperatorProfileEmailForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.root}>
          <Label isMute={true}>メールアドレス</Label>
          <div className={styles.display}>
            {this.props.operator.email}
            <button className={styles.btn} onClick={this.handleOpenModal}>
              変更
            </button>
          </div>
        </div>
        {this.renderModal()}
      </React.Fragment>
    );
  }

  renderModal() {
    return (
      <Modal isOpen={this.state.isOpen} onRequestClose={this.handleCloseModal}>
        <Modal.Header onClose={this.handleCloseModal}>
          <h2>メールアドレス変更</h2>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className={styles.inline}>
              <Label isMute={true} htmlFor="operator-email">
                新しいメールアドレス
              </Label>
              {this.renderApiErrors()}
              <ErrorMessage name="email" component={ErrorText} />
            </div>
            <Field
              name="email"
              type="email"
              id="operator-email"
              component={Input}
            />
            <div className={styles.btnGroup}>
              <Button type="submit" disabled={!this.isSubmittable()}>
                更新
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  renderApiErrors() {
    if (this.props.apiErrors.length > 0) {
      return this.props.apiErrors.map((err: ApiErrorInterface, idx: number) => {
        return <ErrorText key={`email-error-${idx}`}>{err.message}</ErrorText>;
      });
    }
  }

  private handleCloseModal = () => {
    this.setState({ isOpen: false });
  };

  private handleOpenModal = () => {
    this.setState({ isOpen: true });
  };

  private isSubmittable(): boolean {
    return this.props.isValid && !this.props.submitting;
  }
}

const mapPropsToValues = (props: ComponentProps): Values => ({
  email: props.operator.email,
});

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
): void => {
  if (formikBag.props.submitting) return;
  formikBag.props.updateEmail(values.email);
};

export default withFormik({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
  isInitialValid: true,
})(OperatorProfileEmailForm);
